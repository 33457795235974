import { render, staticRenderFns } from "./logcompold.vue?vue&type=template&id=588f8278&scoped=true&"
import script from "./logcompold.vue?vue&type=script&lang=js&"
export * from "./logcompold.vue?vue&type=script&lang=js&"
import style0 from "./logcompold.vue?vue&type=style&index=0&id=588f8278&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "588f8278",
  null
  
)

export default component.exports